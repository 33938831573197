<template>
<div>
  <div class="d-flex align-center justify-space-between pa-4 primary">
    <div style="width:140px">
      <v-text-field v-model="search" prepend-inner-icon="$magnify" background-color="white" :placeholder="$t('common.search')" hide-details outlined dense />
    </div>
    <div v-if="$store.getters.isMarketUser" class="white--text font-weight-bold pointer text-center" @click="$emit('change', page.HELP)">{{ $t("chatSidebar.help") }}</div>
    <v-menu :close-on-content-click="false" bottom left>
      <template v-slot:activator="{ on, attrs }">
        <div style="border: solid 1px white; border-radius: 5px" v-bind="attrs" v-on="on">
          <v-icon color="white" class="pa-1">mdi-dots-vertical</v-icon>
        </div>
      </template>
      <ChatMenu></ChatMenu>
    </v-menu>
  </div>
  <div class="d-flex pa-2 justify-space-between">
    <template v-if="$store.getters.isMarketUser">
    <div v-if="$store.getters.isMarketUser" class="pa-2 pointer" @click="showContacts">
      <v-hover v-slot="{ hover }">
        <v-icon :color="hover || tab === page.CONTACTS ? 'primary' : 'separator'" size="24">$contacts</v-icon>
      </v-hover>
    </div>
    <v-divider v-if="$store.getters.isMarketUser" vertical />
    <div class="pa-2 pointer" @click="$emit('change', page.MARKET)">
      <v-hover v-slot="{ hover }">
        <v-icon :color="hover || tab === page.MARKET ? 'primary' : 'separator'" size="24">$vehicles</v-icon>
      </v-hover>
    </div>
    <v-divider vertical />
    <div class="pa-2 pointer" @click="$emit('change', page.FREIGHT)">
      <v-hover v-slot="{ hover }">
        <v-icon :color="hover || tab === page.FREIGHT ? 'primary' : 'separator'" size="24">$freights</v-icon>
      </v-hover>
    </div>
    <v-divider vertical />
    </template>
    <div class="pa-2 pointer" @click="$emit('change', page.OTHER)">
      <v-hover v-slot="{ hover }">
        <v-icon :color="hover || tab === page.OTHER ? 'primary' : 'separator'" size="24">$others</v-icon>
      </v-hover>
    </div>
    <v-divider vertical />
    <div class="pa-2 pointer" @click="$emit('change', page.LAST)">
      <v-hover v-slot="{ hover }">
        <v-icon :color="hover || tab === page.LAST ? 'primary' : 'separator'" size="24">$last</v-icon>
      </v-hover>
    </div>
  </div>
  <v-divider class="mx-4" />
</div>
</template>
<script>
import debounce from 'lodash.debounce'
import * as page from '../types/chat-page.js'
import ChatMenu from './ChatMenu.vue'
export default {
  name: 'ChatHeader',
  props: { tab: Number },
  data () {
    return {
      page,
      search: null
    }
  },
  components: { ChatMenu },
  computed: {
    newMarket () {
      return this.$store.getters['chat/newMarket']
    },
    newFreight () {
      return this.$store.getters['chat/newFreight']
    },
    newOther () {
      return this.$store.getters['chat/newOther']
    }
  },
  watch: {
    search: debounce(function (val) {
      // if (val.length > 3) {
      //   this.$emit('change', page.SEARCH, val)
      // }

      if (!val && val === undefined && val.length === 0) {
        this.$emit('change', page.SEARCH, '')
      } else {
        this.$emit('change', page.SEARCH, val)
      }

      // this.$emit('change', page.SEARCH, val)
    }, 500)
  },
  methods: {
    async showContacts () {
      await this.$store.dispatch('refreshContacts')
      this.$emit('change', page.CONTACTS)
    }
  }
}
</script>
<style scoped>
</style>
