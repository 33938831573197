
import { themesDomain } from '../../../utils/themeConfig'
const themes = {
  default: {
    primary: '#006446',
    secondary: '#323b3d',
    accent: '#76B62A',
    error: '#d32f2f',
    info: '#3f7ebe',
    success: '#689f38',
    warning: '#fbc02d',
    background: '#f0f0f0',
    backgroundHover: '#82BEAA',
    backgroundChat: '#f5f5f5',
    stateChange: '#d0ebf7',
    expansionPanelActive: '#666',
    separator: '#bebebe',
    lightText: '#787878',
    deal: '#e6e6e6'
  },
  custom: {
    primary: '#8B4513',
    secondary: '#323b3d',
    accent: '#A0522D',
    error: '#d32f2f',
    info: '#3f7ebe',
    success: '#D2691E',
    warning: '#fbc02d',
    background: '#f0f0f0',
    backgroundHover: '#C19A6B',
    backgroundChat: '#f5f5f5',
    stateChange: '#d0ebf7',
    expansionPanelActive: '#666666',
    separator: '#bebebe',
    lightText: '#787878',
    deal: '#e6e6e6'
  }
}

export default {
  namespaced: true,
  state: {
    currentTheme: 'default',
    themes
  },
  mutations: {
    SET_THEME (state, theme) {
      state.currentTheme = theme
    }
  },
  actions: {
    initializeTheme ({ commit }) {
      const tenantName = localStorage.getItem('tenantName')
      const theme = themesDomain[tenantName]
      commit('SET_THEME', theme)
    },
    setTheme ({ commit }, theme) {
      commit('SET_THEME', theme)
    }
  },
  getters: {
    currentTheme: (state) => state.themes[state.currentTheme],
    currentThemeName: (state) => state.currentTheme
  }
}
