var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"white","height":"60","clipped-left":"","clipped-right":"","fixed":"","app":""}},[(_vm.isMobile)?_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleMenuDrawer}}):_vm._e(),_c('v-toolbar-title',{staticClass:"pl-6"},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"contain":"","src":_vm.logoPath,"height":"34","width":"220"},on:{"click":function($event){_vm.$router.push({ path: '/' }).catch(function (err) {})}}})],1),_c('v-spacer'),(!_vm.isDriver)?_c('v-menu',{attrs:{"offset-y":"","max-height":"300","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"mx-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.countNewNotification > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","size":"26"}},'v-icon',attrs,false),on),[_vm._v("$notifications")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":hover ? 'primary' : 'separator',"size":"26"}},'v-icon',attrs,false),on),[_vm._v("$notifications")])]}}],null,true)})],1)]}}],null,false,738184745)},[(_vm.notifications && _vm.notifications.length > 0)?_c('v-list',{staticClass:"py-0"},[_vm._l((_vm.notifications),function(notification,index){return [_c('v-list-item',{key:("notification-" + index),class:{ 'primary lighten-5': !notification.isRead },attrs:{"link":""},on:{"click":function($event){return _vm.notificationClick(notification)}}},[_c('v-list-item-content',[_c('span',[_vm._v(_vm._s(notification.description))])]),_c('v-list-item-action',{staticClass:"justify-start"},[_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(notification.creationDateTime,"DD.MM.YYYY"))+" ")]),_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(notification.creationDateTime,"HH:mm"))+" ")])],1)],1),(_vm.notifications[index + 1])?_c('v-divider',{key:("divider-" + index)}):_vm._e()]})],2):_c('v-list',{staticClass:"py-0"},[_c('v-list-item',[_c('v-list-item-content',[_c('span',[_vm._v(_vm._s(_vm.$t("notifications.noData")))])])],1)],1)],1):_vm._e(),(!_vm.isDriver)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(!_vm.isDriver)?_c('div',{staticClass:"mx-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.chatNotifications > 0)?_c('v-icon',{attrs:{"color":hover ? 'primary' : 'separator',"size":"26"},on:{"click":_vm.toggleChatDrawer}},[_vm._v("$chatMsg")]):_c('v-icon',{attrs:{"color":hover ? 'primary' : 'separator',"size":"26"},on:{"click":_vm.toggleChatDrawer}},[_vm._v("$chat")])]}}],null,false,1607255105)})],1):_vm._e(),(!_vm.isDriver)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(_vm.isUserImpersonalized)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.backToBaseAccount}},[_vm._v(_vm._s(_vm.$t('appBar.disconnect')))]),_c('div',{staticClass:"d-flex flex-column ml-4"},[_c('StatusAvatar',{staticClass:"text-center",attrs:{"userId":_vm.userCommonData.id,"size":20,"showStatus":""}}),_c('span',{staticClass:"text-caption primary--text",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.impersonalizedUserNameAndSurname)+" ")])],1)],1):_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('StatusAvatar',{staticClass:"ml-2",attrs:{"userId":_vm.userCommonData.id,"size":30,"showStatus":""}})],1)]}}])},[_c('v-list',{staticClass:"py-0"},[_vm._l((_vm.userMenu),function(item,index){return [(item.divider)?_c('v-divider',{key:index}):(item.action)?_c('v-list-item',{key:index,on:{"click":item.command}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v(_vm._s(item.label))])],1):_c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v(_vm._s(item.label))])],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }