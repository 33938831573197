import { useFavicon } from '@vueuse/core'
import { themesDomain, faviconConfig } from './themeConfig'

const favicon = useFavicon()

export function updateFavicon (tenant, notification = false) {
  const themeKey = themesDomain[tenant] || 'default'
  const alertSuffix = notification ? '/alert' : ''
  const faviconPath = faviconConfig[themeKey + alertSuffix] || faviconConfig.default

  favicon.value = faviconPath
}
