import { themePaths, logoConfig } from './themeConfig'

export function getLogoPath (store, type = 'primary') {
  const currentTheme = store.getters['theme/currentThemeName']
  const themePath = themePaths[currentTheme] || themePaths.default

  const logoFile = logoConfig[currentTheme]?.[type] || logoConfig.default[type]

  return require(`@/assets/logo/${themePath}/${logoFile}`)
}
