import store from '@/store'

export const getFeatureFlag = (key) => {
  const featureFlags = store.getters.featureFlags
  const featureFlag = featureFlags.find((item) => item.name === key)

  if (!featureFlag) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Feature flag '${key}' doesn't exist`)
    }
    return false
  }

  return featureFlag.active
}
