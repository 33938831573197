import Service from '@/services'
import * as types from './mutation-types'
import { changeLocale } from '@/utils/helpers'
import router from '@/router'

export const loading = ({ commit }) => {
  commit(types.LOADING)
}

export const loaded = ({ commit }) => {
  commit(types.LOADED)
}

export const tenant = async ({ commit }) => {
  const domain = window.location.hostname
  try {
    const { data } = await Service.post('Frontend/GetTenant', { domain })
    data.name && localStorage.setItem('tenantName', data.name)
    commit(types.SET_TENANT, data)
  } catch (e) {}
}

export const enums = async ({ commit }) => {
  try {
    const { data } = await Service.get('Frontend/GetEnums')
    commit(types.ENUMS, data)
  } catch (e) {}
}

export const languages = async ({ commit }) => {
  try {
    const { data } = await Service.get('Frontend/GetAvailableLanguages')
    commit(types.SET_LANGUAGES, data)
  } catch (e) {}
}

export const countries = async ({ commit }) => {
  try {
    const { data } = await Service.get('Frontend/GetAvailableCountries')
    commit(types.SET_COUNTRIES, data)
  } catch (e) {}
}

export const setSuccessNotification = ({ commit }, text) => {
  commit(types.SET_SNACKBAR, {
    type: 'success',
    open: true,
    text,
    timeout: 10000
  })
}

export const setErrorNotification = ({ commit }, text) => {
  commit(types.SET_SNACKBAR, {
    type: 'error',
    open: true,
    text,
    timeout: 10000
  })
}

export const setInfoNotification = ({ commit }, text) => {
  commit(types.SET_SNACKBAR, {
    type: 'info',
    open: true,
    text,
    timeout: 10000
  })
}

export const setSuccessNotificationDialog = ({ commit }, text) => {
  commit(types.SET_NOTIFICATION_DIALOG, {
    type: 'success',
    open: true,
    text
  })
}

export const setErrorNotificationDialog = ({ commit }, payload) => {
  commit(types.SET_NOTIFICATION_DIALOG, {
    type: 'error',
    open: true,
    text: payload.message,
    action: payload.action
  })
}

export const closeNotificationDialog = ({ commit }) => {
  commit(types.SET_NOTIFICATION_DIALOG, {
    open: false
  })
}

export const fetchUserData = async ({ commit, state, dispatch, getters }) => {
  try {
    const { data: userCommonData } = await Service.get(
      'Frontend/GetUserCommonData'
    )
    commit(types.SET_USER_COMMON_DATA, userCommonData)
    commit('chat/SET_DRAWER', userCommonData.launchChatAfterStart)
    await changeLocale(userCommonData.language, state.languages)
    if (!getters.isDriver) {
      await dispatch('chat/fetchSettings', null, { root: true })
      await dispatch('chat/notifications')
    }
    if (userCommonData.role === 98 || userCommonData === 2) {
      await dispatch('refreshContacts')
    }
  } catch (e) {
    await dispatch('logout', true)
  }
}

export const fetchUserCommonData = async ({ commit }) => {
  const { data: userCommonData } = await Service.get(
    'Frontend/GetUserCommonData'
  )
  commit(types.SET_USER_COMMON_DATA, userCommonData)
  commit('chat/SET_DRAWER', userCommonData.launchChatAfterStart)
}

export const initApp = async ({ commit, dispatch }) => {
  await dispatch('tenant')
  await dispatch('theme/initializeTheme')
  await Promise.all([
    dispatch('languages'),
    dispatch('enums'),
    dispatch('countries'),
    dispatch('currencies')
  ])
  if (!localStorage.getItem('token')) {
    commit(types.LOADING, false)
    return
  }
  commit(types.LOGIN, true)
  await dispatch('fetchUserData')
  commit(types.LOADING, false)
}

export const logout = async ({ dispatch }, mobile) => {
  try {
    const logoutWithTimeout = new Promise((resolve, reject) => {
      Service.post('Authentication/Logout', {})
        .then(resolve, reject)
      setTimeout(reject, 1000)
    })

    await logoutWithTimeout
  } finally {
    dispatch('clearSession')

    if (!mobile) {
      await router.push({ name: 'Login' })
    }

    dispatch('resetState')
  }
}

export const clearSession = async ({ commit, dispatch }, mobile) => {
  localStorage.removeItem('token')
  commit(types.SET_USER_COMMON_DATA, {})
  commit(types.LOGIN, false)
  dispatch('chat/clearConversations')
}

export const refreshContacts = async ({ commit }) => {
  const response = await Service.get('Contact/GetContacts')
  commit(types.REFRESH_CONTACTS, response.data)
}

export const currencies = async ({ commit }) => {
  try {
    const { data } = await Service.get('Frontend/GetAvailableCurrencies')
    commit(types.SET_CURRENCIES, data)
  } catch (e) {}
}

export const helpdesk = async ({ commit }) => {
  try {
    const { data } = await Service.get('/Helpdesk/GetConversations')
    commit(types.HELP, data)
  } catch (e) {}
}

export const resetState = async ({ dispatch }) => {
  dispatch('notification/resetState')
  dispatch('market/resetState')
  dispatch('chat/resetState')
}
